import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Testimonial from '../../src/assets/testimonial1.jpg';
import T1 from '../../src/assets/t1.jpg';
import T2 from '../../src/assets/t2.jpg';
import T3 from '../../src/assets/t3.jpg';

const TestimonialSection = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth >= 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section
      className="testimonial-section animate__animated animate__fadeInUp animate__delay-5s"
      style={{
        padding: '20px',
        color: '#fff',
        textAlign: 'center',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        backgroundColor: isLargeScreen?'#fff':'#f4f4df',
        borderRadius: isLargeScreen?'':'8px',
      }}
    >
      <h3
        className="text-uppercase text-start text-success"
        style={{
          fontWeight: '800',
          letterSpacing: '3px',
          fontSize: isLargeScreen?'1.5rem':'',
          transform: 'translateX(2%)',
        }}
      >
        Testimonials
      </h3>
      <h3
      className='text-start'
        style={{
          fontWeight: '600',
          fontSize: isLargeScreen?'2.5rem':'',
          color: 'black',
          letterSpacing: '1px',
          transform: 'translateX(2%)', 
        }}
      >
        What Our Clients Say
      </h3>
      <br/>
      <div className="row position-relative">
      {isLargeScreen && (
  <div className="col-xs-12 col-sm-12">
    <img
      src={Testimonial}
      alt="Main background"
      style={{
        width: isLargeScreen ? '80%' : '100%',
        height: 'auto',
        marginLeft: isLargeScreen ? '-20%' : '1%',
      }}
    />
  </div>
)}
        <div
          className={`col-xs-12 col-md-6 ${
            isLargeScreen ? 'position-absolute' : 'position-static'
          }`}
          style={{
            top: isLargeScreen ? '50%' : 'auto%',
            left: isLargeScreen ? '60%' : 'auto',
            overflow: 'hidden',
            width: isLargeScreen ? '50%' : '100%',
            zIndex: 30,
            transform: isLargeScreen ? 'translateY(-50%)' : 'none',
          }}
        >
          <Carousel
            controls={true}
            indicators={true}
            interval={8000}
            pause={false}
            prevIcon={
              <span
                aria-hidden="true"
                className="carousel-control-prev-icon"
                style={{ display: 'none' }}
              />
            }
            nextIcon={
              <span
                aria-hidden="true"
                className="carousel-control-next-icon"
                style={{ display: 'none' }}
              />
            }
          >
            {[
              {
                name: 'Jullia',
                role: 'Team Captain',
                imgSrc: T3,
                rating: 5,
                feedback:
                  'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don.',
              },
              {
                name: 'Anna Smith',
                role: 'Project Lead',
                imgSrc: T2,
                rating: 5,
                feedback:
                  'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',
              },
              {
                name: 'Mark Johnson',
                role: 'Senior Consultant',
                imgSrc: T1,
                rating: 4,
                feedback:
                  'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 fromde Finibus Bonorum et Malorum.',
              },
            ].map((client, index) => (
              <Carousel.Item key={index}>
                <div className="row align-items-center justify-content-start mt-5">
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ textAlign: 'left', gap: '20px' }}
                  >
                    <img
                      src={client.imgSrc}
                      alt={`${client.name} - Client`}
                      className="img-responsive"
                      style={{
                        borderRadius: '50%',
                        width: '180px',
                        height: '180px',
                        objectFit: 'cover',
                        border: '4px solid #28a745',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                    <div>
                      <h4
                        className="text-uppercase"
                        style={{
                          marginBottom: '5px',
                          fontWeight: '700',
                          color: '#000',
                          fontSize: '1.5rem',
                          letterSpacing: '1px',
                        }}
                      >
                        {client.name}
                      </h4>
                      <span
                        style={{
                          color: '#000',
                          fontSize: '1rem',
                          marginBottom: '5px',
                          display: 'block',
                          fontStyle: 'italic',
                        }}
                      >
                        {client.role}
                      </span>
                      <div className="rating">
                        {[...Array(5)].map((_, i) => (
                          <span
                            key={i}
                            style={{
                              color: i < client.rating ? '#ffc107' : '#6c757d',
                              fontSize: '1.5rem',
                              marginRight: '3px',
                            }}
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div
                      className="testimonial-content animate__animated animate__fadeInLeft"
                      style={{
                        fontSize: '1.1rem',
                        lineHeight: '1.8',
                        color: '#f8f9fa',
                        marginTop: '20px',
                        marginBottom: '40px',
                        padding: '30px',
                        backgroundColor: '#212529',
                        borderRadius: '15px',
                        textAlign: 'center',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    >
                      <p style={{ margin: 0, fontStyle: 'italic' }}>
                        "{client.feedback}"
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
