import React, { useState,useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/animate.css';
import '../css/animation.css';
import '../css/bootstrap.min.css';
import '../css/elegenticon.css';
import '../css/font-awesome.css';
import '../css/fontawesome.min.css';
import '../css/hover.css';
import '../css/icofont.min.css';
import '../css/jquery-ui.css';
import '../css/jquery.fancybox.min.css';
import '../css/jquery.mCustomScrollbar.min.css';
import '../css/linearicons.css';
import '../css/main.css';
import '../css/owl.css';
import '../css/responsive.css';
import '../css/simple-line-icons.css';
import '../css/themify-icons.css';
import logo from '../assets/logo.png';
import logow from '../assets/logoww.png';
import logo1 from '../assets/logo1.png';
import MobileMenu from '../components/MobileMenu';
import { Modal } from '@mui/material';
import MenuModal from './MenuModal';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
    setTimeout(() => {
      const formElement = document.getElementById("form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100); // Allow time for navigation
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [blogDropdownOpen, setBlogDropdownOpen] = useState(false);
  const location = useLocation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Effect to listen for scroll events
  useEffect(() => {
    const handleScroll = () => {
      // Set scroll state to true if page is scrolled down by 50px or more
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen);
  };

  const toggleBlogDropdown = () => {
    setBlogDropdownOpen(!blogDropdownOpen);
  };


  const underlineStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '30px',
    height: '2px',
    backgroundColor: '#3a9d6a',
  };
 

  return (
    <>
      <header className="main-header" style={{backgroundColor:isLargeScreen?'':location.pathname === '/contact' || location.pathname === '/'?'#f4f4df':''}}>
      <div className={isLargeScreen ? "header-upper" : ""}>
          <div className="auto-container">
            <div className="inner-container clearfix">
              <div className="pull-left logo-box">
                <div className="logo">
                {(location.pathname === "/about" || location.pathname === "/service") && isLargeScreen && !scrolled ? (
  <Link to="/">
    <img src={logow} alt="White Logo" title="About Logo" width={150} />
  </Link>
) : (
  <Link to="/">
    <img
      src={logo}
      alt="Default Logo"
      title="Default Logo"
      width={isLargeScreen ? 150 : 160}
      height={100}
    />
  </Link>
)}


    
                </div>
              </div>

              <div className="nav-outer pull-left clearfix">
                <nav className="main-menu navbar-expand-md">
                  <div className="navbar-header">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>

                  <div className="navbar-collapse show collapse clearfix" id="navbarSupportedContent">
                    <ul className="navigation clearfix">
                      <li style={{ position: 'relative' }}>
                      <Link 
        to="/"
        style={{
          color: (scrolled ? 'black' : '') || (
          location.pathname === '/about' || location.pathname === '/service' ? 'white' :
            location.pathname === '/blog-detail' || location.pathname === '/faq' || location.pathname === '/blog' || 
            location.pathname === '/service/investment' || location.pathname === '/service/wealth' || location.pathname === '/service/financial' || 
            location.pathname === '/service/corporate' ? 'black' : location.pathname === '/' ? '#3a9d6a' : 'black'
          )
        }}
      >
        Home
      </Link>

                        {location.pathname === '/' && (
                          <span style={underlineStyle} />
                        )}
                      </li>
                      <li style={{ position: 'relative' }}>
                      <Link 
        to="/about" 
        style={{
          color: (scrolled ? 'black' : '') || (
           location.pathname === '/service' ? 'white' :
            location.pathname === '/' || location.pathname === '/blog-detail' || location.pathname === '/' || 
            location.pathname === '/faq' || location.pathname === '/blog' || location.pathname === '/service/investment' || 
            location.pathname === '/service/wealth' || location.pathname === '/service/financial' || location.pathname === '/service/corporate' ? 
            'black' : (location.pathname === '/about' ? '#3a9d6a' : 'black')
          )
        }}
      >
        About Us
      </Link>
                        {location.pathname === '/about' && (
                          <span style={underlineStyle} />
                        )}
                      </li>
                      <li
  className={`dropdown ${location.pathname === '/service' ? 'active' : ''}`}
  style={{ position: 'relative' }}
>
<Link 
        to="/service"
        style={{
          color: (scrolled ? 'black' : '') || (
            location.pathname === '/about' ? 'white' :
            location.pathname === '/' || location.pathname === '/blog-detail' || location.pathname === '/faq' || 
            location.pathname === '/blog' || location.pathname === '/service/investment' || location.pathname === '/service/wealth' || 
            location.pathname === '/service/financial' || location.pathname === '/service/corporate' ? 'black' :
            location.pathname === '/service' ? '#3a9d6a' : 'black'
          )
        }}
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={false}
      >
        Solutions
      </Link>
  {location.pathname === '/service' && <span style={underlineStyle} />}

  <ul
    className={`dropdown-menu ${isDropdownVisible ? 'dropdown-menu-visible' : ''}`}
    aria-labelledby="dropdownMenuLink"
    style={{
      display: isDropdownVisible ? 'block' : 'none',
      position: 'absolute',
      left: 0,
      top: '100%',
      backgroundColor: '#fff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '10px',
      listStyle: 'none',
      margin: 0,
      borderRadius: '5px',
      width: '400px',
    }}
  >
    <li>
      <Link
        to="/service"
        className={`dropdown-item ${location.pathname === '/service' ? '' : ''}`}
        onMouseOver={() => setDropdownVisible(true)}>
        Our Solutions
      </Link>
    </li>
    {isDropdownVisible && (
      <ul className="dropdown-submenu" style={{ paddingLeft: '10px' }}>
        <li>
          <Link
            to="/service/wealth"
            className={`dropdown-item ${location.pathname === '/service/wealth' ? 'active' : ''}`}
            style={{ textDecoration: 'none' }}
          >
            Wealth Management
          </Link>
        </li>
        <li>
          <Link
            to="/service/investment"
            className={`dropdown-item ${location.pathname === '/service/investment' ? 'active' : ''}`}
            style={{ textDecoration: 'none' }}
          >
            Investment Advisory
          </Link>
        </li>
        <li>
          <Link
            to="/service/financial"
            className={`dropdown-item ${location.pathname === '/service/financial' ? 'active' : ''}`}
            style={{ textDecoration: 'none' }}
          >
            Financial Planning
          </Link>
        </li>
        <li>
          <Link
            to="/service/corporate"
            className={`dropdown-item ${location.pathname === '/service/corporate' ? 'active' : ''}`}
            style={{ textDecoration: 'none' }}
          >
            Corporate Financial Management
          </Link>
        </li>
      </ul>
    )}
  </ul>
</li>


                      
                      <li style={{ position: 'relative' }}>
                      <Link 
        to="/faq"
        style={{
          color: (scrolled ? 'black' : '') || (
            location.pathname === '/about' || location.pathname === '/service' ? 'white' :
            location.pathname === '/' || location.pathname === '/service/investment' || location.pathname === '/service/wealth' || 
            location.pathname === '/service/financial' || location.pathname === '/service/corporate' || location.pathname === '/blog-detail' || 
            location.pathname === '/blog' ? 'black' : location.pathname === '/faq' ? '#3a9d6a' : 'black'
          )
        }}
      >
        FAQ
      </Link>
                        {location.pathname === '/faq' && (
                          <span style={underlineStyle} />
                        )}
                      </li>
                      <li className={`dropdown ${location.pathname === '/blog' || location.pathname === '/blog-detail' ? 'active' : ''}`} style={{ position: 'relative' }}>
                      <Link 
        to="/blog"
        style={{
          color: (scrolled ? 'black' : '') || (
            location.pathname === '/about' || location.pathname === '/service' ? 'white' :
            location.pathname === '/' || location.pathname === '/blog-detail' || location.pathname === '/faq' || location.pathname === '/blog' || 
            location.pathname === '/service/investment' || location.pathname === '/service/wealth' || location.pathname === '/service/financial' || 
            location.pathname === '/service/corporate' ? 'black' : location.pathname === '/blog' ? '#3a9d6a' : 'black'
          )
        }}
      >
        Blog
      </Link>
                        {location.pathname === '/blog' && (
                          <span style={underlineStyle} />
                        )}
                        <ul className="from-right">
                          <li><Link to="/blog" style={{ color: location.pathname === '/blog' ? '#3a9d6a' : 'black' }}>Our Blog</Link></li>
                          <li><Link to="/blog-detail" style={{ color: location.pathname === '/blog-detail' ? '#3a9d6a' : 'black' }}>Blog Detail</Link></li>
                        </ul>
                      </li>
                      <li style={{ position: 'relative' }}>
                      <Link 
        to="/contact"
        style={{
          color: (scrolled ? 'black' : '') || (
            location.pathname === '/about' || location.pathname === '/service' ? 'white' :
            location.pathname === '/' || location.pathname === '/service/investment' || location.pathname === '/service/wealth' || 
            location.pathname === '/service/financial' || location.pathname === '/service/corporate' || location.pathname === '/blog' || 
            location.pathname === '/blog-detail' || location.pathname === '/faq' ? 'black' : location.pathname === '/contact' ? '#3a9d6a' : 'black'
          )
        }}
      >
        Contact
      </Link>
                        {location.pathname === '/contact' && (
                          <span style={underlineStyle} />
                        )}
                      </li>
                    </ul>
                   
                         
                    <div className="col-md-2 col-sm-3 col-xs-6">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {location.pathname === '/' || location.pathname === '/faq' || location.pathname === '/blog' || location.pathname === '/blog-detail' || location.pathname === '/service/investment'|| location.pathname === '/service/wealth'|| location.pathname === '/service/financial'|| location.pathname === '/service/corporate'? ( 
            <button className="btn btn-success"
       style={{
        background: "transparent",
        color: '#000',
        padding: '5px 30px',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '30px',
        border: "1px solid black",
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        whiteSpace: 'nowrap', 
        display: 'inline-block',
        transform:'translateX(40px)',
       
      }}
        onClick={
          handleClick
          
        }
         >
  Let's Start
</button>
) : ( 
<button 
        className="btn btn-success"
        style={{
          background: "transparent",
          color: (scrolled ? 'black' : '') || (
           location.pathname === '/about' || location.pathname === '/service' ? 'white' : 'black'
          ),
          padding: '5px 30px',
          fontSize: '0.9rem',
          fontWeight: 'bold',
          borderRadius: '30px',
         border: `1px solid ${(scrolled ? 'black' : '')}`,
          transition: 'transform 0.2s ease, box-shadow 0.2s ease',
          whiteSpace: 'nowrap',
          display: 'inline-block',
          transform: 'translateX(40px)',
        }}
        onClick={handleClick}
      >
        Let's Start
      </button>
)}
            <button
  className="btn"
  style={{
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    transform:'translateX(50px)',
  }}
  onClick={() => setIsModalOpen(true)}
>
{location.pathname === '/' || location.pathname === '/faq' || location.pathname === '/blog' || location.pathname === '/blog-detail' || location.pathname === '/service/investment'|| location.pathname === '/service/wealth'|| location.pathname === '/service/financial'|| location.pathname === '/service/corporate'? ( 
  <span
    className="ti-menu"
    style={{
      fontWeight: 'bold',
      fontSize: '18px',
      color: 'black',
    }}
  ></span>
) : (
  <span
        className="ti-menu"
        style={{
          fontWeight: 'bold',
          fontSize: '18px',
          color: (scrolled ? 'black' : '') || (
             location.pathname === '/about' || location.pathname === '/service' ? 'white' : 'black'
          ),
        }}
      ></span>
)}

</button>

          </div>
        </div>
                  </div>
                  
                </nav>
                <MobileMenu />
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div>
        <MenuModal 
          onClose={() => { 
            setIsModalOpen(false); 
          }} 
        />
      </div>
    </Modal>
    </>
  );
}
